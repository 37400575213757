import Vue from 'vue/dist/vue.esm';
import template from './next_step_lock_toggle_template.slim';

Vue.component('next-step-lock-toggle', {
  mixins: [template],
  props: [
    'inputId',
    'isLocked',
    'handleClick'
  ]
});
