import Vue from 'vue/dist/vue.esm'
import VueFlashMessage from 'vue-flash-message'
import VueGoodTablePlugin from 'vue-good-table'
import VueApexCharts from 'vue-apexcharts'
import Rollbar from 'rollbar'

Vue.use(VueFlashMessage)
Vue.use(VueGoodTablePlugin)
Vue.use(VueApexCharts)

Vue.prototype.$appData = () => {
  return document.getElementById('app').dataset
}
Vue.prototype.$usesGroupObservations = () => {
  return Vue.prototype.$appData().usesGroupObservations === 'true'
}
if (
  process.env.RAILS_ENV == 'production' ||
  process.env.RAILS_ENV == 'staging'
) {
  Vue.prototype.$rollbar = new Rollbar({
    accessToken: process.env.ROLLBAR_POST_CLIENT_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: process.env.RAILS_ENV,
    payload: {
      code_version: '1.0.0',
    },
  })
}

document.addEventListener('turbolinks:load', () => {
  var app = document.getElementById('app')
  if (app != undefined) {
    const App = new Vue({
      el: app,
      mounted() {
        Vue.prototype.$schoolSlug = this.$el.dataset.schoolSlug
        Vue.prototype.$isMobile = this.$el.dataset.isMobile
        window.fadeFlashes()
      },
    })
  }
})

function initFreshChat() {
  if (window.fcWidget && process.env.FRESH_DESK) {
    window.fcWidget.init({
      token: process.env.FRESH_DESK,
      host: 'https://wchat.freshchat.com',
      config: {
        headerProperty: {
          hideChatButton: true
        }
      }
    })
  }
}
function initialize(i, t) {
  var e
  i.getElementById(t)
    ? initFreshChat()
    : (((e = i.createElement('script')).id = t),
      (e.async = !0),
      (e.src = 'https://wchat.freshchat.com/js/widget.js'),
      (e.onload = initFreshChat),
      i.head.appendChild(e))
}
function initiateCall() {
  if (
    process.env.RAILS_ENV == 'production' ||
    process.env.RAILS_ENV == 'staging'
    ) {
    initialize(document, 'freshchat-js-sdk')
  }
}
window.addEventListener
  ? window.addEventListener('load', initiateCall, !1)
  : window.attachEvent('load', initiateCall, !1)

document.addEventListener('turbolinks:load', () => {
  var app = document.getElementById('vue-container')
  if (app != undefined) {
    const App = new Vue({
      el: app,
    })
  }
})

//require components from ./components
import './components/card_mastery_bar'
import './components/checkbox_panels'
import './components/checkbox_radio_input'
import './components/datetime_picker'
import './components/dropdown_menu'
import './components/dropdown_button'
import './components/modal'
import './components/modal_overlay'
import './components/navigation'
import './components/new_session_modal'
import './components/session_components/assessment_template_select'
import './components/session_components/assessors_multiselect'
import './components/session_components/classroom_select'
import './components/session_components/learners_multiselect'
import './components/session_components/learner_select'
import './components/session_components/objectives_multiselect'
import './components/session_components/people_multiselect'
import './components/session_components/title_input'
import './components/session_components/notes_wysiwyg'
import './components/text_input'
import './components/slide_up_panel'
import './components/scores'
import './components/scoring_row'
import './components/score_all_panel'
import './components/video_player'
import './components/student_details'
import './components/historical_score_card'
import './components/editable'
import './components/district_all_sessions_report'
import './components/district_feedback_sessions_report'
import './components/district_teachers_with_feedback_report'
import './components/district_self_reflection_sessions_report'
import './components/district_next_step_report'
import './components/district_peer_visit_sessions_report'
import './components/district_per_school_report'
import './components/sessions_per_learner_by_school_report'
import './components/district_next_step_per_school_report'
import './components/district_session_types_by_school_report'
import './components/users_by_school_with_active_goal_report'
import './components/district_all_objectives_report'
import './components/tooltip'

//require individual pages from ./pages
import './pages/dashboard'
import './pages/session_templates'
import './pages/mobile_track_group'
import './pages/mobile_track_individual'
import './pages/session_template'
import './pages/launch_session'
import './pages/assessment_templates'
import './pages/active_goals_report'
import './pages/active_goal_objectives_report'
import './pages/student_objectives_report'
import './pages/staff'

// register 3rd party components
import vSelect from 'vue-select'
Vue.component('v-select', vSelect)
import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)
import Datetime from 'vue2-datepicker'
Vue.component('datetime', Datetime)
import { Carousel, Slide } from 'vue-carousel'
Vue.component('carousel', Carousel)
Vue.component('slide', Slide)
import Editor from '@tinymce/tinymce-vue'
Vue.component('editor', Editor)
Vue.component('apexchart', VueApexCharts)

// http library => https://www.npmjs.com/package/axios
import axios from 'axios'
let token = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = token
axios.defaults.headers.common['Accept'] = 'application/json'

import Turbolinks from 'turbolinks'

Turbolinks.start()
