import Vue from 'vue/dist/vue.esm'
import template from './sessions_per_learner_by_school_report_template.slim'
import moment from 'moment'
import { getUrlWithSlug, getWidth, seriesDataBar, mergeCountsBar } from '../commonChartFunctions';

Vue.component('sessions_per_learner_by_school_report', {
  mixins: [template],
  props: ['session_data', 'school_year_label', 'year_info', 'report_date'],
  data() {
    return {
      startDate: '',
      endDate: '',
      series: [{
        name: 'sessions',
        data: seriesDataBar(mergeCountsBar(this.session_data, this.startDate, this.endDate))
      }],
      chartOptions: {
        dataLabels: {
          enabled: false
        },
        chart: {
          type: 'bar',
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const schoolName = config.w.config.series[0].data[config.dataPointIndex].x;
              const urlWithSlug = getUrlWithSlug(this.year_info.current_schools_year, schoolName);
              const redirectUrl = urlWithSlug + "/frequency_reports";
              window.open(redirectUrl, '_blank');
            }
          }
        },
        yaxis: {
          title: {
            text: "Sessions per Learner",
            style: {
              color: '#949494'
            }
          },
          forceNiceScale: true,
          labels: {
            formatter: function (value, index) {
              return value.toFixed(2);
            }
          },
        },
        xaxis: {
          labels: {
            trim: true,
            rotate: -55,
          }
        },
        grid: {
          row: {
            colors: ['#FFFFFF', '#F3F3F3']
          },
          padding: {
            left: 50,
            bottom: 30
          },
        }
      }
    }
  },
  mounted() {
      this.wholeYearRangeClicked();
  },
  computed: {
    chartWidth: function() {
      return getWidth(this.series[0].data.length);
    },
    isCurrentYear: function() {
      return this.year_info.is_current_school_year
    },
    currentDate: function() {
      return moment().format('MMMM D, YYYY');
    },
  },
  methods: {
    sevenDayRangeClicked: function() {
      this.setDayRange(7);
    },
    thirtyDayRangeClicked: function() {
      this.setDayRange(30);
    },
    wholeYearRangeClicked: function() {
      this.startDate = this.year_info.school_start_date;
      this.endDate = moment().endOf('day');
      this.updateChart();
    },
    setDayRange: function(day) {
      this.startDate = moment().subtract(day + 1, "days");
      this.endDate = moment().subtract(1, "days");
      this.updateChart();
    },
    updateChart: function() {
      this.$refs.perSchoolApexChart.updateSeries([{
        data: seriesDataBar(mergeCountsBar(this.session_data, this.startDate, this.endDate))
      }]);
    }
  }
});
