import Vue from 'vue/dist/vue.esm'
import template from './district_all_sessions_report_template.slim'
import moment from 'moment'
import { seriesData, mergeCounts } from '../commonChartFunctions';

Vue.component('district_all_sessions_report', {
  mixins: [template],
  props: ['session_data', 'school_year_label', 'year_info', 'report_date'],
  data() {
    return {
      startDate: '',
      endDate: '',
      series: [{
        name: 'sessions',
        data: seriesData(mergeCounts(this.session_data))
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: true,
            autoScaleYaxis: true
          },
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          title: {
            text: "District sessions",
            style: {
              color: '#949494'
            }
          },
          forceNiceScale: true
        },
        grid: {
          row: {
            colors: ['#FFFFFF', '#F3F3F3']
          }
        }
      }
    }
  },
  mounted() {
      this.wholeYearRangeClicked();
  },
  computed: {
    sumOfSessions: function() {
      return _.reduce(mergeCounts(this.session_data), (sum, val, key) => {
        if (moment(key).isBetween(this.startDate, this.endDate)) {
         return sum + val
        } else {
          return sum
        }
      }, 0)
    },
    pluralizedSessions: function() {
      return this.sumOfSessions === 1 ? "session" : "sessions";
    },
    isCurrentYear: function() {
      return this.year_info.is_current_school_year
    },
    currentDate: function() {
      return moment().format('MMMM D, YYYY');
    },
  },
  methods: {
    sevenDayRangeClicked: function() {
      this.setDayRange(7);
    },
    thirtyDayRangeClicked: function() {
      this.setDayRange(30);
    },
    wholeYearRangeClicked: function() {
      this.startDate = moment(`${this.year_info.school_start_date}`);
      this.endDate = moment().endOf('day');
      this.setChartZoom();
    },
    setDayRange: function(day) {
      this.startDate = moment().subtract(day + 1, "days");
      this.endDate = moment().subtract(1, "days");
      this.setChartZoom();
    },
    setChartZoom: function() {
      this.$refs.apexChart.zoomX(this.startDate?.valueOf(), this.endDate?.valueOf());
    }
  }
});
