import Vue from 'vue/dist/vue.esm'
import template from './classroom_students_template.slim'
import { EventBus } from '../../../../event-bus.js'

Vue.component('classroom-students', {
  mixins: [template],
  props: ['form', 'sessionOptions', 'learnerOptions', 'learners', 'usesGroupObservations'],
  data() {
    return {
      filterBy: '',
    }
  },
  methods: {
    clearInput() {
      this.filterBy = ''
    },
    getLearners() {
      let result
      if (this.learners.length > 0) {
        result = window.confirm(
          'Changing classroom will reset selected leaners. Continue?'
        )
      } else {
        EventBus.$emit('fetch-learners')
      }

      if (result) {
        EventBus.$emit('fetch-learners')
      }
    },
    determineSelected(id) {
      return this.learners.slice().includes(id)
    },
    returnFiltered() {
      return this.learnerOptions.filter(obj => {
        return obj.label.toLowerCase().includes(this.filterBy.toLowerCase())
      })
    },
    selectLearner(learner) {
      EventBus.$emit('select-learner', learner)
    },
  },
  mounted() {},
})
