import Vue from 'vue/dist/vue.esm'
import { EventBus } from '../../event-bus.js'
import template from './mobile_track_individual_template.slim'
import axios from 'axios'
import './components/score_card_panel'
import './components/medium-panel'
import './components/next_step_lock_toggle'
import _ from 'lodash'
import moment from 'moment';

Vue.component('mobile-track-individual', {
  mixins: [template],
  props: [
    'school_slug',
    'session',
    'learning_resources',
    'objectives_options',
    'is_iphone',
    'learner',
    'past_assessments',
    'goal_objective_names',
    'past_objective_scores',
    'next_step_data',
    'next_steps',
    'learners_options',
    'current_staff',
    'available_tags',
    'score_band',
    'resource_center_enabled_for_school',
    'rewrite_notes_enabled'
  ],
  data() {
    return {
      checklistItems: this.session.objectives,
      initialChecklistItems: [],
      activeAccordionItems: [],
      searchQuery: '',
      selectedItems: this.getInitialSelectedItems(),
      sessionState: this.session,
      learning_resource: this.learning_resources,
      sessionEdits: this.initializeSessionEdits(this.session),
      sessionNote: this.session.notes,
      sessionNotification: this.session.notification,
      teacherLearner: this.learner.learner_type === 'Staff',
      objectiveNote: null,
      notesWysiwygToggleKey: 0,
      pastObjectiveScores: this.past_objective_scores,
      selectedObjective: {},
      showSessionNote: false,
      showSessionMedium: false,
      isDraft: this.session.draft,
      isNotified: false,
      modalOpen: false,
      sessionId: this.session.id,
      learnerName: this.session.assessee.full_name,
      observer: this.session.assessor.full_name,
      goal: this.session.goal,
      sessionName: this.session.assessment_name,
      menuHidden: true,
      tagsDropdownMenuHidden: true,
      availableTags: this.available_tags,
      showSessionNotification: false,
      nextStepList: [],
      commentsList: [],
      singleNextStep: [],
      completedNextSteps: [],
      notesSaving: false,
      nextStep: {
        title: '',
        description: '',
        dueDate: '',
        nextStepOwner: null,
        objective: [],
        media: null,
        links: [],
        mediaUrl: '',
        uploadedMedia: [],
        selectedResources: [],
        locked: this.next_step_data.locked
      },
      comment: {
        body: '',
        notificationIds: [],
        media: null,
        links: [],
        mediaUrl: '',
        uploadedMedia: [],
        selectedResources: [],
      },
      progress: '0%',
      isNextStepEdit: true,
      progressClass: '',
      fetching: false,
      isIframeLinkCheck: false,
      iframeLinkUrl: '',
      mediumDataCollection: [],
      showLinkInput: false,
      linkInput: '',
      showResourceDrawer: false,
      resourceSearchQuery: '',
      addResourceSearchQuery: '',
      resources: [],
      nextStepResources: [],
      objectiveResources: [],
      singleResourceDataForObjective: {},
      linkedUrls: [],
      mediaUrl: '',
      showSingleResourcePanel: false,
      editedNextStep: {
        id: 0,
        title: '',
        description: '',
        dueDate: '',
        nextStepOwner: [],
        objectives: [],
        locked: false,
      },
      toBeDeletedNextStep: {
        id: 0
      },
      selectAllOwnersChecked: false,
      rewriteNotesEnabled: this.rewrite_notes_enabled,
      $rewriteNotesPopup: null
    }
  },

  computed: {
    filteredResources() {
      return this.objectiveResources.filter(resource =>
        resource.name.toLowerCase().includes(this.resourceSearchQuery.toLowerCase())
      );
    },
    displayedLinksOnEditMode() {
      return this.nextStep.links.filter(link => !link._destroy);
    },
    isIframeLink() {
      return this.nextStep.links.map((url, index) => !!this.iframeLinkUrls[index]);
    },
    filteredAddResources() {
      return this.resources.filter(resource => resource.name.toLowerCase().includes(this.addResourceSearchQuery.toLowerCase()));
    },
    filteredChecklistItems() {
      const query = this.searchQuery.toLowerCase();
      return this.checklistItems
        .map(item => ({
          ...item,
          checklist_items_list: item.checklist_items_list.filter(subItem =>
            subItem.name.toLowerCase().includes(query)
          ),
        }))
        .filter(item => item.checklist_items_list.length > 0);
    },
    checkedItemsCount() {
      return this.checklistItems.filter(item => item.checklist_items_list.length > 0).map(item =>
        item.checklist_items_list.filter(subItem => subItem.checked).length
      );
    },
    isActiveAccordionItem() {
      return (index, itemId) => this.activeAccordionItems.includes(index) || itemId === this.selectedObjective.id;
    },
    selectedCheckboxes() {
      this.selectedItems = this.checklistItems.flatMap(item =>
        item.checklist_items_list.filter(subItem => subItem.checked).map(subItem => ({
          label: `${item.title} > ${subItem.name}`,
        }))
      );
      return this.selectedItems;
    },
    hasNotes() {
      return (
        this.sessionState.notes !== '' &&
        this.sessionState.notes !== '<p></p>' &&
        this.sessionState.notes !== null

      )
    },
    hasNotification() {
      return (
        this.sessionState.notification !== '' &&
        this.sessionState.notification !== '<p></p>' &&
        this.sessionState.notification !== null
      )
    },
    hasMedium() {
      return !_.isEmpty(this.sessionState.media[0]?.medium_url)
    },
    noGoal() {
      return this.goal == null
    },
    learnersOptions() {
      return Object.values(this.learners_options);
    },
    nextSteps(){
      return this.nextStepList
    },
    isNextStepLocked() {
      return this.next_step_data.locked;
    }
  },
  created(){
    this.getNextStepList()
    this.initialChecklistItems = _.cloneDeep(this.checklistItems);
  },
  methods: {
    isCommentIframeLink(comment) {
      return comment.commentLinks.map((url, index) => !!comment.iFrameUrls[index]);
    },
    formatDate(dateString) {
      if (!dateString) return '';
    
      return moment(dateString).format('M/D/YY h:mm A');
    },
    formatDueDateForAddEditNextStep(dateString) {
      if ([null, ""].includes(dateString)) {
        return "";
      }
      const dateObj = new Date(dateString.replace(/(\d+)(st|nd|rd|th)/, '$1'));
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    getNextStepList(){
      axios.get(`/go/${this.school_slug}/v2/next_steps?session_id=${this.session.id}`,)
        .then(response => {
          this.nextStepList = response.data
        })
        .catch(error => {
          const statusCode = error.response.status;
        })
    },
    getCommentsList(id) {
      axios.get(`/go/${this.school_slug}/comments?next_step_id=${id}`,)
        .then(async response => {
          const processedComments = await Promise.all(response.data.map(async comment => {
            const { commentUrls, iFrameUrls, commentLinks, webLinkData } = await this.commentLinkedUrls(comment.resource_center.linked_urls);

            return {
              ...comment,
              linkedUrls: commentUrls,
              iFrameUrls: iFrameUrls,
              commentLinks: commentLinks,
              webLinkData: webLinkData
            };
          }));
          this.commentsList = processedComments;
        })
        .catch(error => {
          const statusCode = error.response.status;
        })
    },
    selectAllOwners() {
      if (this.selectAllOwnersChecked) {
        this.nextStep.nextStepOwner = this.learnersOptions
      } else {
        this.nextStep.nextStepOwner = null
      }
    },
    changeAllOwnersCheckbox() {
      this.selectAllOwnersChecked = this.nextStep.nextStepOwner.length === this.learnersOptions.length;
    },
    initializeSessionEdits(session) {
      return {
        scheduled_at: session.scheduled_at,
        assessment_name: session.assessment_name,
        objectives: _.filter(this.objectives_options, option =>
          _.includes(session.objective_ids, option.value.toString())
        ),
        staff: _.filter(session.assignable_staff_as_option, option =>
          _.includes(session.staff_ids, option.value)
        ),
        assignable_staff_as_option: session.assignable_staff_as_option,
        errors: {},
      }
    },
    publishSession() {
      window.location.pathname = `/go/${this.school_slug}/individual_sessions/${
        this.session.id
      }/notification/new`
    },
    saveAndExit() {
      window.location.pathname = `go/${this.school_slug}/v2/dashboard`
    },
    togglePublishMenu() {
      this.menuHidden = !this.menuHidden;
    },
    handleClick(event) {
      if($(event.target).hasClass('tags-dropdown-button')) {
        this.tagsDropdownMenuHidden = !this.tagsDropdownMenuHidden;
      } else if($(event.target).closest('.mobile-session-tags .dropdown-menu').length > 0) {
        this.tagsDropdownMenuHidden = false;
      } else {
        this.tagsDropdownMenuHidden = true;
      }
    },
    scoreAllParams() {
      return {
        recipients: 'All Objectives',
        subject: this.sessionState.assessee.full_name,
        scores: this.sessionState.mastery_scores,
        subject_id: this.sessionState.assessee.id,
      }
    },
    applyAutoHeightStyle() {
      $('head').append('<style>' +
        'html,body {' +
        '     height: auto;' +
        '}' +
        '</style>');
    },
    toggleNotificationDraft() {
      this.showSessionNotification = !this.showSessionNotification
      this.applyAutoHeightStyle()
    },
    toggleSessionNote() {
      this.showSessionNote = !this.showSessionNote
      this.showSessionMedium = false
      this.applyAutoHeightStyle()

      if (!this.showSessionNote && this.$rewriteNotesPopup.is(":visible")) {
        this.$rewriteNotesPopup.fadeOut();
      }
    },
    toggleHistoricalSessionNotes() {
      this.$refs.historicalSessionNotes.toggleVisible()
    },
    toggleSessionMedium() {
      this.showSessionMedium = !this.showSessionMedium
      this.showSessionNote = false

      if (this.$rewriteNotesPopup.is(":visible")) {
        this.$rewriteNotesPopup.fadeOut();
      }
    },
    toggleScoreAllPanel() {
      this.$refs.scoreAllPanel.toggleVisible()
    },
    openObjectiveDetailsModal(objective) {
      this.selectedObjective = objective
      this.$refs.objectiveDetailsPanel.toggleVisible()
      this.objectiveResources = objective.learning_resources_list;
    },
    openCheckListItemsModal(objective) {
      this.selectedObjective = objective
      this.$refs.checkListPanel.toggleVisible()
      document.querySelector('.check-list-footer')?.classList.add('fixed-footer')
    },
    openObjectiveNoteNodal(objective) {
      this.selectedObjective = objective
      this.objectiveNote = this.findOrInitializeObjectiveNote()
      this.notesWysiwygToggleKey += 1
      this.$refs.objectiveNotePanel.toggleVisible()
      this.applyAutoHeightStyle()
    },
    findOrInitializeObjectiveNote() {
      let note = ''
      const { mastery_scores } = this.sessionState
      const score = _.find(mastery_scores, {
        mastery_objective_id: this.selectedObjective.id,
      })
      if (score && score.notes) {
        note = score.notes
      }
      return note
    },
    updateObjectiveNote(input) {
      this.objectiveNote = input.target.value
    },
    onPressCloseObjectiveNote() {
      this.$refs.objectiveNotePanel.toggleVisible()
    },
    onPressCancelNextStep() {
      this.$refs.addNextStepPanel.toggleVisible()
    },
    async generateIframeLink(url) {
      const youtubeRegex = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^&\n?#]+)/;
      const loomRegex = /loom\.com\/share\/([^&\n?#]+)/;
      const vimeoRegex = /vimeo\.com\/(.*\/)?([^#?]*)/;
      const microsoftRegex = /web.microsoftstream.com\/video\/([^&\n?#]+)/;
      const tiktokRegex = /tiktok\.com\/(?:@[^\/]+\/video\/|v\/)([^&\n?#]+)/;
  
      const youtubeMatch = url.match(youtubeRegex);
      const loomMatch = url.match(loomRegex);
      const vimeoMatch = url.match(vimeoRegex);
      const microsoftMatch = url.match(microsoftRegex);
      const tiktokMatch = url.match(tiktokRegex);
      let videoDetail;

      if (youtubeMatch && youtubeMatch[1]) {
        videoDetail = await this.getVideoDetails(url, 'youtube');
        return { embedUrl: 'https://www.youtube.com/embed/' + youtubeMatch[1], thumbnail_path: videoDetail.thumbnail_path };
      } else if (loomMatch && loomMatch[1]) {
        videoDetail = await this.getVideoDetails(url, 'loom');
        return { embedUrl: 'https://www.loom.com/embed/' + loomMatch[1], thumbnail_path: videoDetail.thumbnail_path };
      } else if (vimeoMatch && vimeoMatch[2]) {
        videoDetail = await this.getVideoDetails(url, 'vimeo');
        return { embedUrl: 'https://player.vimeo.com/video/' + videoDetail.videoId, thumbnail_path: videoDetail.thumbnail_path };
      } else if (microsoftMatch && microsoftMatch[1]) {
        videoDetail = await this.getVideoDetails(url, 'microsoft');
        return { embedUrl: 'https://web.microsoftstream.com/embed/video/' + microsoftMatch[1], thumbnail_path: videoDetail.thumbnail_path };
      } else if (tiktokMatch && tiktokMatch[1]) {
        videoDetail = await this.getVideoDetails(url, 'tiktok');
        return { embedUrl: 'https://www.tiktok.com/embed/v2/' + tiktokMatch[1], thumbnail_path: videoDetail.thumbnail_path };
      } else {
        let urlMetadata = await this.fetchUrlMetadata(url);
        return { embedUrl: null, platform: null, image: urlMetadata?.image, thumbnail_path: urlMetadata?.image, isWebLink: true };
      }
    },
    async fetchUrlMetadata(url) {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: `/fetch_url_metadata`,
          method: 'GET',
          data: { url: url },
          success: function(data) {
            resolve(data);
          },
          error: function(jqXHR, textStatus, errorThrown) {
            console.error("Error fetching metadata:", textStatus, errorThrown);
            reject(errorThrown);
          }
        });
      });
    },
    async getVideoDetails(videoUrl, platform) {
      let oembedUrl = ""
      if (platform === 'youtube') {
        const videoId = videoUrl.match(/embed\/([^?&]+)/)?.[1];
        return {
          videoId: videoId,
          thumbnail_path: null
        };
      }

      if (platform === 'loom') {
        oembedUrl = `https://www.loom.com/v1/oembed?url=${videoUrl}`;
      } else if (platform === 'vimeo') {
        oembedUrl = `https://vimeo.com/api/oembed.json?url=${videoUrl}`;
      } else if (platform === 'microsoft') {
        oembedUrl = `https://web.microsoftstream.com/oembed?url=${videoUrl}`;
      } else if (platform === 'tiktok') {
        oembedUrl = `https://www.tiktok.com/oembed?url=${videoUrl}`;
      }

      try {
        let response = await fetch(oembedUrl);
        let data = await response.json();
        
        return { 
          videoId: data.video_id || videoUrl.match(/embed\/([^?&]+)/)?.[1], 
          thumbnail_path: data.thumbnail_url 
        };
      } catch (error) {
        console.error("Error fetching video metadata:", error);
        return null;
      }
    },
    async openSingleResourcePanel(resource) {
      this.singleResourceDataForObjective = resource;
      this.showSingleResourcePanel = true;
      this.$refs.singleResourcePanel.toggleVisible();

      if (resource.linked_url) {
        const iframeLinkUrl = await this.generateIframeLink(resource.linked_url);
        this.isIframeLinkCheck = !!iframeLinkUrl?.embedUrl;
        this.iframeLinkUrl = iframeLinkUrl?.embedUrl || '';
        
        this.singleResourceDataForObjective = {
          ...resource,
          isIframe: this.isIframeLinkCheck,
          isWebLink: !!iframeLinkUrl?.isWebLink,
          linked_url: iframeLinkUrl?.embedUrl || resource.linked_url,
          thumbnail_path: iframeLinkUrl?.thumbnail_path || resource.thumbnail_path
        };
      }
    },
    triggerFileUpload() {
      if(this.progress === '100%') {
        this.$refs.uploadMedia.click();
      } else {
        alert("Wait until file is uploading!")
      }
    },
    initializeFileUpload() {
      $(this.$refs.uploadMedia).fileupload({
        replaceFileInput: false,
        add: (e, data) => {
          this.fetching = true;
          this.fileName = data.files[0].name;
          this.progressClass = 'fetching';
          this.mediumUrl = false;
          this.error = null;
          const options = { filename: data.files[0].name, _: Date.now() };
          $.getJSON('/resources/cache/presign', options, (result) => {
            data.formData = result.fields;
            data.url = result.url;
            data.paramName = 'file';
            data.submit();
          });
        },
        progress: (e, data) => {
          this.progress = `${parseInt((data.loaded / data.total) * 100, 10)}%`;
        },
        done: (e, data) => {
          this.progressClass = 'saving';
          const mediumData = {
            id: data.formData.key.match(/cache\/(.+)/)[1],
            storage: 'cache',
            metadata: {
              size: data.files[0].size,
              filename: data.files[0].name.match(/[^\/\\]+$/)[0],
              mime_type: data.files[0].type,
            },
            medium: data.files[0],
            _destroy: false,
          };
          this.mediumDataCollection.push(mediumData);
        },
        fail: (e, data) => {
          this.fetching = false;
          this.progress = '0%';
          this.progressClass = 'error';
          this.mediumUrl = this.medium ? this.medium.medium_url : false;
          this.error = data.response().errorThrown;
        },
      });
    },
    handleFileUpload(event) {
      this.initializeFileUpload()
      const files = event.target.files;
      for (let file of files) {
        const mediaUrl = URL.createObjectURL(file);
        this.nextStep.uploadedMedia.push({ file, mediaUrl });
      }
    },
    handleCommentFileUpload(event) {
      this.initializeFileUpload()
      const files = event.target.files;
      for (let file of files) {
        const mediaUrl = URL.createObjectURL(file);
        this.comment.uploadedMedia.push({ file, mediaUrl });
      }
    },
    removeMedia(index, media, type) {
      if (type === "add") {
        this.nextStep.uploadedMedia.splice(index, 1);
        const itemIndex = this.mediumDataCollection.findIndex(
          (item) => item.medium.name === media.file.name
        );
        if (itemIndex !== -1) {
          this.mediumDataCollection.splice(itemIndex, 1);
        }
      } else {
        this.nextStep.uploadedMedia.splice(index, 1);
        const itemIndex = this.mediumDataCollection.findIndex(
          (item) => item.metadata.filename === media.file.name
        );
        if (itemIndex !== -1) {
          this.mediumDataCollection[itemIndex]._destroy = true;
        }
      }
      this.progress = "100%";
      this.fetching = true;
    },
    removeCommentMedia(index, media) {
      this.comment.uploadedMedia.splice(index, 1);
      const itemIndex = this.mediumDataCollection.findIndex(
        (item) => item.medium.name === media.file.name
      );
      if (itemIndex !== -1) {
        this.mediumDataCollection.splice(itemIndex, 1);
      }

      this.progress = "100%";
      this.fetching = true;
    },
    closeResourceLinkDrawer() {
      this.showLinkInput = false;
      this.removeLink()
    },
    addLink() {
      if (this.linkInput) {
        this.generateIframeLink(this.linkInput)
          .then(iframeLinkUrl => {
            const newLink = {
              url: iframeLinkUrl?.embedUrl || this.linkInput,
              isIframe: !!iframeLinkUrl?.embedUrl,
              isWebLink: !!iframeLinkUrl?.isWebLink,
              thumbnail_path: iframeLinkUrl?.thumbnail_path || null,
              _destroy: false,
            };
    
            this.nextStep.links.push(newLink);
    
            if (iframeLinkUrl?.embedUrl) {
              this.iframeLinkUrls.push(iframeLinkUrl.embedUrl);
            } else {
              this.iframeLinkUrls.push(null);
            }
    
            this.linkInput = '';
            this.showLinkInput = false;
          })
          .catch((error) => {
            console.error("Error generating iframe link:", error);
          });
      }
    },
    async commentLinkedUrls(urls) {
      let commentUrls = []
      let iframeCommentLinkUrls = []
      let commentLinks = []
      let webLinkData = []
      
      for (const link of urls) {
        const url = link.url;
        commentUrls.push(url);
        
        const iframeLinkUrl = await this.generateIframeLink(url);
        if (iframeLinkUrl?.embedUrl) {
          iframeCommentLinkUrls.push(iframeLinkUrl.embedUrl);
          commentLinks.push({ 
            url: iframeLinkUrl.embedUrl, 
            isIframe: true,
            thumbnail_path: iframeLinkUrl.thumbnail_path || null 
          });
          webLinkData.push(null);
        } else {
          iframeCommentLinkUrls.push(null);
          commentLinks.push({ 
            url: url, 
            isIframe: false,
            thumbnail_path: null 
          });
          webLinkData.push({
            thumbnail_path: iframeLinkUrl?.thumbnail_path || iframeLinkUrl?.image
          });
        }
      }

      return { 
        commentUrls, 
        iFrameUrls: iframeCommentLinkUrls, 
        commentLinks,
        webLinkData 
      };
    },
    addCommentLink() {
      if (this.linkInput) {
        this.generateIframeLink(this.linkInput)
          .then(iframeLinkUrl => {
            const newLink = {
              url: iframeLinkUrl?.embedUrl || this.linkInput,
              isIframe: !!iframeLinkUrl?.embedUrl,
              isWebLink: !!iframeLinkUrl?.isWebLink,
              thumbnail_path: iframeLinkUrl?.thumbnail_path || iframeLinkUrl?.image || '',
              _destroy: false,
            };
    
            this.comment.links.push(newLink);
            this.linkInput = '';
            this.showLinkInput = false;
          });
      }
    },
    removeLink(index, type, linkId) {
      if (type === 'edit' && linkId) {
        const linkIndex = this.nextStep.links.findIndex(link => link.id === linkId);
        if (linkIndex !== -1) {
          this.$set(this.nextStep.links, linkIndex, {
            ...this.nextStep.links[linkIndex],
            _destroy: true,
          });
        }
      } else {
        this.nextStep.links.splice(index, 1);
        this.iframeLinkUrls.splice(index, 1);
      }
    },
    removeCommentLink(index) {
      this.comment.links.splice(index, 1);
    },
    openLinkDrawer() {
      this.linkInput = [];
      this.showLinkInput = true;
      this.iframeLinkUrl = '';
    },
    openResourceDrawer() {
      this.showResourceDrawer = true;
      this.fetchResources();
    },
    openCommentResourceDrawer() {
      this.showResourceDrawer = true;
      this.fetchCommentResources();
    },
    addSelectedResources() {
      this.showResourceDrawer = false;
    },
    fetchResources() {
      const filteredResourcesBySelectedReources = this.learning_resource.filter(resource =>
        !this.nextStep.selectedResources.some(selectedResId => selectedResId.id === resource.id)
      );
      this.resources = filteredResourcesBySelectedReources;
    },
    fetchCommentResources() {
      const filteredResourcesBySelectedReources = this.learning_resource.filter(resource =>
        !this.comment.selectedResources.some(selectedResId => selectedResId.id === resource.id)
      );
      this.resources = filteredResourcesBySelectedReources;
    },
    selectResource(resource) {
      if (!this.nextStep.selectedResources.includes(resource)) {
        this.nextStep.selectedResources.push(resource);
      }
      this.showResourceDrawer = false;
    },
    selectCommentResource(resource) {
      if (!this.comment.selectedResources.includes(resource)) {
        this.comment.selectedResources.push(resource);
      }
      this.showResourceDrawer = false;
    },
    removeSelectedResource(index) {
      this.nextStep.selectedResources.splice(index, 1);
    },
    removeCommentResource(index) {
      this.comment.selectedResources.splice(index, 1);
    },
    handleClickOnNextStepLock(nextStepType) {
      let childRef;
      let nextStep;

      if (nextStepType === "addNextStepLocked") {
        childRef = this.$refs.addNextStepLocked;
        nextStep = this.nextStep;
      } else if (nextStepType === "editNextStepLocked") {
        childRef = this.$refs.editNextStepLocked;
        nextStep = this.editedNextStep;
      }

      if (childRef && nextStep) {
        const inputElement = childRef.$refs.checkboxInput;
        nextStep.locked = !nextStep.locked;
        inputElement.checked = nextStep.locked;
      }
    },
    onPressSaveNextStep() {
      const formattedDueDate = this.formatDueDateForAddEditNextStep(this.nextStep.dueDate);
      const objectiveIds = this.nextStep.objective.map(obj => obj.value);
      const ownerIds = this.nextStep.nextStepOwner?.map(obj => obj.value);
      const resourceId = this.nextStep.selectedResources?.map(res => res.id);
    
      const formData = new FormData();
      formData.append('next_step[session_id]', this.sessionId);
      formData.append('next_step[assigned_by_id]', this.assignedById);
      formData.append('next_step[creation_location_type]', 'IndividualAssessment');
      formData.append('next_step[creation_location_id]', this.sessionId);
      formData.append('next_step[title]', this.nextStep.title);
      formData.append('next_step[description]', this.nextStep.description);
      formData.append('next_step[locked]', this.nextStep.locked);
      this.nextStep.links.forEach((link, index) => {
        formData.append(`next_step[linked_urls_attributes][${index}][id]`, '');
        formData.append(`next_step[linked_urls_attributes][${index}][url]`, link.url);
        formData.append(`next_step[linked_urls_attributes][${index}][thumbnail_path]`, link.thumbnail_path || '');
        formData.append(`next_step[linked_urls_attributes][${index}][_destroy]`, false);
      });
    
      if (resourceId) {
        resourceId.forEach(id => {
          formData.append('search[]', id);
          formData.append('next_step[learning_resource_ids][]', id);
        });
      }
    
      for (let i = 0; i < this.mediumDataCollection.length; i++) {
        const uploadedFile = this.mediumDataCollection[i];
        const fileId = `${Date.now()}${i}`;
    
        formData.append(`next_step[media_attributes][${fileId}][id]`, '');
        formData.append(`next_step[media_attributes][${fileId}][medium]`, uploadedFile.medium);
        formData.append(`next_step[media_attributes][${fileId}][medium_data]`, JSON.stringify({
          id: uploadedFile.id,
          storage: 'cache',
          metadata: uploadedFile.metadata
        }));
        formData.append(`next_step[media_attributes][${fileId}][_destroy]`, false);
      }
    
      if (ownerIds) {
        ownerIds.forEach(id => {
          formData.append('next_step[owner_ids][]', id);
        });
      }
    
      formData.append('next_step[due_date]', formattedDueDate);
    
      if (objectiveIds) {
        objectiveIds.forEach(id => {
          formData.append('next_step[objective_ids][]', id);
        });
      }
    
      axios.post(`/go/${this.school_slug}/v2/next_steps`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          this.getNextStepList();
          this.onPressCancelNextStep();
          this.clearData();
          this.selectAllOwnersChecked = false;
        })
        .catch(error => {
          const statusCode = error.response.status;
        });
    },
    onPressSaveComment() {
      const resourceId = this.comment.selectedResources?.map(res => res.id);
      const notificationIds = this.comment.notificationIds?.map(n => n.id);

      const formData = new FormData();
      formData.append('comment[body]', this.comment.body);
      formData.append('comment[commentable_id]', this.singleNextStep.id);
      formData.append('comment[commentable_type]', "NextStep");

      if (notificationIds.length) {
        formData.append('comment[notification_ids][]', notificationIds);
      }

      this.comment.links.forEach((link, index) => {
        formData.append(`comment[linked_urls_attributes][${index}][id]`, '');
        formData.append(`comment[linked_urls_attributes][${index}][url]`, link.url);
        formData.append(`comment[linked_urls_attributes][${index}][thumbnail_path]`, link.thumbnail_path || '');
        formData.append(`comment[linked_urls_attributes][${index}][_destroy]`, false);
      });
    
      if (resourceId) {
        resourceId.forEach(id => {
          formData.append('search[]', id);
          formData.append('comment[learning_resource_ids][]', id);
        });
      }
    
      for (let i = 0; i < this.mediumDataCollection.length; i++) {
        const uploadedFile = this.mediumDataCollection[i];
        const fileId = `${Date.now()}${i}`;
    
        formData.append(`comment[media_attributes][${fileId}][id]`, '');
        formData.append(`comment[media_attributes][${fileId}][medium]`, uploadedFile.medium);
        formData.append(`comment[media_attributes][${fileId}][medium_data]`, JSON.stringify({
          id: uploadedFile.id,
          storage: 'cache',
          metadata: uploadedFile.metadata
        }));
        formData.append(`comment[media_attributes][${fileId}][_destroy]`, false);
      }
    
      axios.post(`/go/${this.school_slug}/comments`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(async response => {
          const processedComments = await Promise.all(response.data.map(async comment => {
            const { commentUrls, iFrameUrls, commentLinks, webLinkData } = await this.commentLinkedUrls(comment.resource_center.linked_urls);
            return {
              ...comment,
              linkedUrls: commentUrls,
              iFrameUrls: iFrameUrls,
              commentLinks: commentLinks,
              webLinkData: webLinkData
            };
          }));
          this.commentsList = processedComments;
          this.clearData();
        })
        .catch(error => {
          const statusCode = error.response.status;
        });
    },
    confirmDeletion(commentId) {
      if (window.confirm('Are you sure you want to delete this comment?')) {
        this.deleteComment(commentId);
      }
    },
    deleteComment(id) {
      axios.delete(`/go/${this.school_slug}/comments/${id}`)
        .then(async response => {
          const processedComments = await Promise.all(response.data.map(async comment => {
            const { commentUrls, iFrameUrls, commentLinks, webLinkData } = await this.commentLinkedUrls(comment.resource_center.linked_urls);
            return {
              ...comment,
              linkedUrls: commentUrls,
              iFrameUrls: iFrameUrls,
              commentLinks: commentLinks,
              webLinkData: webLinkData
            };
          }));
          this.commentsList = processedComments;
          this.clearData();
        })
        .catch(error => {
          const statusCode = error.response.status;
        });
    },
    onPressUpdateNextStep(id) {
      const formattedDueDate = this.formatDueDateForAddEditNextStep(
        this.editedNextStep.dueDate
      );
      const objectiveIds = this.editedNextStep.objectives.map(
        (obj) => obj.value
      );
      const resourceId = this.nextStep.selectedResources?.map((res) => res.id);
      let mediaAttributes = {};

      for (let i = 0; i < this.mediumDataCollection.length; i++) {
        const uploadedFile = this.mediumDataCollection[i];
        const fileId = `${Date.now()}${i}`;

        mediaAttributes[fileId] = {
          id: uploadedFile?.ids ? uploadedFile?.ids : "",
          medium: uploadedFile.medium,
          medium_data: {
            id: uploadedFile.id,
            storage: "cache",
            metadata: uploadedFile.metadata,
          },
          _destroy: !!uploadedFile._destroy,
        };
      }

      const linksAttributes = this.nextStep.links.map((link, index) => {
        return {
          id: link.id || '',
          url: link.url,
          thumbnail_path: link.thumbnail_path || '',
          _destroy: link._destroy || false,
        };
      });

      const data = {
        next_step: {
          title: this.editedNextStep.title,
          description: this.editedNextStep.description,
          due_date: formattedDueDate,
          owner_id: this.editedNextStep.nextStepOwner.value,
          objective_ids: objectiveIds,
          linked_urls_attributes: linksAttributes,
          learning_resource_ids: resourceId,
          media_attributes: mediaAttributes,
          locked: this.editedNextStep.locked
        },
      };

      axios
        .patch(
          `/go/${this.school_slug}/v2/next_steps/${this.editedNextStep.id}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.getNextStepList();
          this.$refs.singleNextStepEditPanel.toggleVisible();
          this.clearData();
        })
        .catch((error) => {
          const statusCode = error.response.status;
        });
    },
    onPressDeleteNextStep(id) {
      this.toBeDeletedNextStep.id = id;
      this.$refs.singleNextStepEditPanel.toggleVisible();
      this.$refs.modalBackdrop.toggleVisible();
      this.$refs.deleteNextStep.toggleVisible();
    },
    onPressCancelDeleteNextStep() {
      this.clearData();
      this.$refs.deleteNextStep.toggleVisible();
      this.$refs.modalBackdrop.toggleVisible();
    },
    onPressConfirmDeleteNextStep() {
      axios
        .delete(`/go/${this.school_slug}/v2/next_steps/${this.toBeDeletedNextStep.id}`)
          .then(response => {
            this.getNextStepList();
            this.$refs.deleteNextStep.toggleVisible();
            this.clearData();
            this.$refs.modalBackdrop.toggleVisible();
          })
          .catch(error => {
            const statusCode = error.response.status;
          });
    },
    clearData(){
      this.nextStep = {
        title:'',
        description:'',
        dueDate:'',
        nextStepOwner:null,
        objective:[],
        media: null,
        links: [],
        mediaUrl: '',
        uploadedMedia: [],
        selectedResources: [],
        locked: this.next_step_data.locked
      }
      this.comment = {
        body: '',
        notificationIds: [],
        media: null,
        links: [],
        mediaUrl: '',
        uploadedMedia: [],
        selectedResources: [],
      }
      this.editedNextStep = {
        id: 0,
        title: '',
        description: '',
        dueDate: '',
        nextStepOwner: [],
        objectives: [],
        locked: false
      }
      this.toBeDeletedNextStep = {
        id: 0
      }
      this.mediumDataCollection = []
    },
    saveObjectiveNote() {
      this.toggleNotesSaving();
      const note = {
        mastery_objective_id: this.selectedObjective.id,
        notes: this.objectiveNote,
      }
      axios
        .patch(
          `/go/${this.school_slug}/v2/individual_sessions/${
            this.session.id
          }/make_score_note`,
          {
            note,
          }
        )
        .then(response => {
          const { score, assessee_recent_scores } = response.data
          this.sessionState.mastery_scores = this.updatedMasteryScores([score])
          this.sessionState.assessee_recent_scores = assessee_recent_scores
          this.toggleNotesSaving();
        })
        .catch(error => {
          console.error(error)

          alert("Failed to save note");
        })
    },
    getScore(objective) {
      return _.find(this.sessionState.mastery_scores, {
        mastery_objective_id: objective.id,
      })
    },
    getScoreParams(objective, score) {
      return {
        mastery_score: {
          mastery_objective_id: objective.id,
          score,
        },
      }
    },
    updateScores(scores) {
      axios
        .patch(
          `/go/${this.school_slug}/v2/individual_sessions/${
            this.session.id
          }/score`,
          {
            scores,
          }
        )
        .then(response => {
          const { mastery_scores, assessee_recent_scores } = response.data
          this.sessionState.mastery_scores = this.updatedMasteryScores(
            mastery_scores
          )
          this.sessionState.assessee_recent_scores = assessee_recent_scores
        })
        .catch(error => {
          console.error(error)

          const statusCode = error.response.status;
          if (statusCode == 401 || statusCode == 404) { document.location.reload(); }
        })
    },
    onPressSaveAllScores(score) {
      const scores = _.map(this.sessionState.objectives, objective =>
        this.getScoreParams(objective, score)
      )
      this.updateScores(scores)
      this.$refs.scoreAllPanel.toggleVisible()
    },
    updateScore(objective, score) {
      const scores = [this.getScoreParams(objective, score)]
      this.updateScores(scores)
    },
    updatedMasteryScores(newMasteryScores) {
      return _.unionWith(
        newMasteryScores,
        this.sessionState.mastery_scores,
        (a, b) => {
          return (
            a.student_id === b.student_id &&
            a.mastery_objective_id === b.mastery_objective_id
          )
        }
      )
    },
    onPressCloseSessionNote() {
      this.toggleSessionNote()
    },
    saveSessionNoteNotification(onSuccess=_.noop) {
      axios
        .patch(
          `/go/${this.school_slug}/v2/individual_sessions/${
            this.session.id
          }/update_notes`,
          {
            notes: this.sessionNote,
            notification: this.sessionNotification
          }
        )
        .then(response => {
          this.sessionState.notes = this.sessionNote
          this.sessionState.notification = this.sessionNotification
          this.toggleNotesSaving();
          onSuccess()
        })
        .catch(error => {
          console.error(error)

          alert("Failed to save note");
        })
    },
    toggleNotesSaving() {
      if(this.notesSaving) {
        setTimeout(() => {
          this.notesSaving = false;
        }, 500);
      } else {
        this.notesSaving = true;
      }
    },
    saveSessionNote() {
      this.toggleNotesSaving();
      this.saveSessionNoteNotification()
    },
    saveSessionNotification() {
      this.saveSessionNoteNotification(this.toggleNotificationDraft)
    },
    cancelNotification() {
      this.sessionNotification = this.sessionState.notification
      this.toggleNotificationDraft()
    },
    openSessionEditPanel() {
      this.$refs.sessionEditPanel.toggleVisible()
    },
    openNextStepAddPanel() {
      $('#next-step-due-date').find('input').val('');
      this.$refs.addNextStepPanel.toggleVisible();
      this.clearData();
    },
    openNextStepAddComment() {
      this.$refs.addNextStepComment.toggleVisible();
      this.initializeFileUpload();
      this.clearData();
    },
    toggleElementsUponNextStepCompletion(isCompleted) {
      let el = $('#show-single-next-step');
      if (isCompleted) {
        el.find('.edit-pencil-icon').hide();
        el.find('.completion-data').show();
      }
      else {
        this.singleNextStep.completed = false;
        el.find('.edit-pencil-icon').show();
        el.find('.completion-data').hide();
      }
    },
    showSingleNextStep(id) {
      this.fetchSingleNextStep(id);
      this.getCommentsList(id);
      this.toggleElementsUponNextStepCompletion(this.singleNextStep.completed);
      this.$refs.showSingleNextStep.toggleVisible()
    },
    showCompletedNextStepHistory(render_location_id, render_location) {
      this.fetchCompletedNextStepHistory(render_location_id, render_location);
      this.$refs.completedNextStepPanel.toggleVisible()
    },
    fetchCompletedNextStepHistory(render_location_id, render_location){
      axios.get(`/go/${this.school_slug}/v2/next_steps/completed_next_steps_history?render_location_id=${render_location_id}&render_location=${render_location}`)
          .then(response => {
            this.completedNextSteps = response.data
          })
          .catch(error => {
            const statusCode = error.response.status;
          })
    },
    fetchSingleNextStep(id) {
      axios.get(`/go/${this.school_slug}/v2/next_steps/${id}`)
        .then(async (response) => {
          this.singleNextStep = response.data;
          this.singleNextStep.created_at = this.formatDate(this.singleNextStep.created_at);
          this.singleNextStep.assginedByName = this.singleNextStep.assigned_by.name;
          this.singleNextStep.ownerName = this.singleNextStep.owner.name;
          this.singleNextStep.objectiveName = this.singleNextStep.objective_names.join(', ');
          this.singleNextStep.canBeEditedOrDeletedByCurrentStaff = response.data.can_be_edited_or_deleted_by_current_staff;
          this.singleNextStep.canBeCompletedByCurrentStaff = response.data.can_be_completed_by_current_staff;
          this.nextStepResources = response.data.resource_center.learning_resources;
          this.mediaUrl = response.data.resource_center.media;
  
          this.linkedUrls = [];
          this.iframeLinkUrls = [];
          this.nextStep.links = [];
    
          const promises = response.data.resource_center.linked_urls.map((linkData) => {
            return this.generateIframeLink(linkData.url).then((iframeLinkUrl) => {
              const newLink = {
                url: iframeLinkUrl?.embedUrl || linkData.url,
                isIframe: !!iframeLinkUrl?.embedUrl,
                isWebLink: !!iframeLinkUrl?.isWebLink,
                thumbnail_path: iframeLinkUrl?.thumbnail_path || null
              };
              this.nextStep.links.push(newLink);
              
              if (iframeLinkUrl?.embedUrl) {
                this.iframeLinkUrls.push(iframeLinkUrl.embedUrl);
                this.linkedUrls.push(iframeLinkUrl.embedUrl);
              } else {
                this.iframeLinkUrls.push('');
                this.linkedUrls.push(linkData.url);
              }
            });
          });
    
          await Promise.all(promises);
        })
        .catch(error => {
          const statusCode = error.response.status;
        });
    },
    truncateText(text, length) {
      return text.length > length ? text.substring(0, length) + '...' : text;
    },
    openSingleNextStepEditPanel(id) {
      this.initializeSingleNextStepEdit(id)
      this.$refs.showSingleNextStep.toggleVisible()
      this.$refs.singleNextStepEditPanel.toggleVisible()
    },
    initializeSingleNextStepEdit(id) {
      axios.get(`/go/${this.school_slug}/v2/next_steps/${id}/edit`)
        .then(response => {
          let nextStepData = response.data;
          this.editedNextStep.id = nextStepData.id;
          this.editedNextStep.title = nextStepData.title;
          this.editedNextStep.description = nextStepData.description;
          this.editedNextStep.dueDate = nextStepData.due_date;
          this.editedNextStep.locked = nextStepData.locked;
          this.editedNextStep.canBeEditedOrDeletedByCurrentStaff = nextStepData.can_be_edited_or_deleted_by_current_staff;
          this.$refs.singleNextStepEditPanelDueDate.value =
            nextStepData.due_date;
          this.editedNextStep.nextStepOwner = _.filter(
            this.learners_options,
            (option) =>
              _.includes(
                [nextStepData.owner_id.toString()],
                option.value.toString()
              )
          );
          this.editedNextStep.objectives = _.filter(
            this.objectives_options,
            (option) =>
              _.includes(nextStepData.objective_ids, option.value.toString())
          );
          this.nextStep.selectedResources = [];
          this.nextStep.selectedResources.push(
            ...response.data.resource_center.learning_resources
          );

          const previousMediaData = response.data.resource_center.media.map(
            (item) => ({
              id: item.medium_data.id,
              storage: item.medium_data.storage,
              metadata: item.medium_data.metadata,
              medium: {},
              _destroy: false,
              ids: item.id,
            })
          );

          this.mediumDataCollection = [...previousMediaData];

          this.nextStep.uploadedMedia = [];
          for (let item of response.data.resource_center.media) {
            const file = new File(
              [item.medium_data.metadata.filename],
              item.medium_data.metadata.filename,
              {
                type: item.mime_type,
                lastModified: new Date(item.updated_at).getTime(),
              }
            );

            const mediaUrl = item.medium_url;

            this.nextStep.uploadedMedia.push({ file, mediaUrl });
          }
          let mediaAttributes = {};

          for (let i = 0; i < response.data.resource_center.media.length; i++) {
            const uploadedFile = response.data.resource_center.media[i];
            const fileId = `${Date.now()}${i}`;

            mediaAttributes[fileId] = {
              id: "",
              medium: {},
              medium_data: uploadedFile.medium_data,
              _destroy: !!uploadedFile._destroy,
            };
          }

          this.linkedUrls = [];
          this.iframeLinkUrls = [];
          this.nextStep.links = [];

          response.data.resource_center.linked_urls.forEach((linkData) => {
            this.generateIframeLink(linkData.url)
              .then(iframeLinkUrl => {
                const newLink = {
                  id: linkData.id || '',
                  url: iframeLinkUrl?.embedUrl || linkData.url,
                  isIframe: !!iframeLinkUrl?.embedUrl,
                  isWebLink: !!iframeLinkUrl?.isWebLink,
                  thumbnail_path: iframeLinkUrl?.thumbnail_path || null,
                  _destroy: false,
                };
                this.nextStep.links.push(newLink);
              });
          });
        })
        .catch(error => {
          const statusCode = error.response.status;
        });
    },
    toggleNextStep(id, type){
      let element = $('.next-step-mark[data-id="' + id + '"]');
      if (element) {
        element.css('opacity', '0.7');
        element.attr('disabled', 'disabled');
      }

      axios.post(`/go/${this.school_slug}/v2/next_steps/${id}/completion_toggle`,{
        headers: {
          'Content-Type': 'application/json'
        },
        completion_location_type: 'IndividualAssessment',
        completion_location_id: this.session.id,
        completed_by_id: this.current_staff.id
      })
      .then(response => {
        this.fetchSingleNextStep(id);
        const index = this.nextStepList.findIndex(obj => obj.id === id);
        this.nextStepList[index] = { ...this.nextStepList[index], completed: response.data.completed };

        element.css('opacity', '1');
        element.removeAttr('disabled');

        if (type === "view") {
          this.toggleElementsUponNextStepCompletion(response.data.completed);
        }

        this.getNextStepList();
      })
      .catch(error => {
        const statusCode = error.response.status;
      })
    },
    onPressCancelEditSession() {
      this.sessionEdits = this.initializeSessionEdits(this.sessionState)
      this.$refs.sessionEditPanel.toggleVisible()
    },
    onPressSaveSession() {
      axios
        .patch(
          `/go/${this.school_slug}/v2/individual_sessions/${this.session.id}`,
          {
            individual_session: this.individualSessionParams(),
          }
        )
        .then(response => {
          const updatedSession = response.data
          this.sessionState = updatedSession
          this.sessionEdits = this.initializeSessionEdits(updatedSession)
          this.selectedObjective = {}
          this.$refs.sessionEditPanel.toggleVisible()
          this.checklistItems = updatedSession.objectives
          this.initialChecklistItems = _.cloneDeep(this.sessionState.objectives)
        })
        .catch(error => {
          this.sessionEdits.errors = error.response.data
        })
    },
    onPressDeleteSession() {
      if (confirm('Are you sure you want to delete this session?')) {
        axios
          .delete(
            `/go/${this.school_slug}/v2/individual_sessions/${this.session.id}`
          )
          .then(response => {
            window.location = response.data;
          })
          .catch(err => {
            this.sessionEdits.errors = `Unable to delete session due to: ${err}`;
          })
      }
    },
    individualSessionParams() {
      const { assessment_name, scheduled_at } = this.sessionEdits
      return {
        assessment_name,
        scheduled_at,
        objective_ids: _.map(this.sessionEdits.objectives, o => o.value),
        staff_ids: _.map(this.sessionEdits.staff, o => o.value),
      }
    },
    saveMedium(medium_data, mediumId, successCallback, failureCallback) {
      axios
        .patch(
          `/go/${this.school_slug}/v2/individual_sessions/${
            this.session.id
          }/update_medium`,
          { medium_data, medium_id: mediumId }
        )
        .then(response => {
          const media = response.data
          this.sessionState.media = media
          const new_medium = media.slice(-2)[0]
          successCallback(
            new_medium.medium_url,
            new_medium.mime_type,
            new_medium.id
          )
        })
        .catch(error => {
          failureCallback("Couldn't Save Media")
        })
    },
    deleteMedium(mediumId, onSaveMediumFailed) {
      axios
        .patch(
          `/go/${this.school_slug}/v2/individual_sessions/${
            this.session.id
          }/delete_medium`,
          { medium_id: mediumId }
        )
        .then(response => {
          const remaining_media = _.reject(this.sessionState.media, [
            'id',
            mediumId,
          ])
          this.sessionState.media = remaining_media
        })
        .catch(error => {
          onSaveMediumFailed("Couldn't Delete Media")
        })
    },
    openLearnerDetailsModal(objective) {
      this.selectedObjective = objective
      this.$refs.learnerDetailsPanel.toggleVisible()
    },
    recentScoresForSelectedObjective() {
      const { assessee_recent_scores } = this.sessionState
      return _.get(_.find(assessee_recent_scores, {
        mastery_objective_id: this.selectedObjective.id?.toString()
      }), 'recent_scores')
    },
    recentNotesForSelectedObjective() {
      const recent_scores = this.recentScoresForSelectedObjective()

      return _.reject(recent_scores, score => _.isEmpty(score.notes)).map(
        score => ({
          note: score.notes,
          session_name: score.source,
          formatted_date: score.formatted_date,
          staff: { full_name: score.staff_name },
        })
      )
    },
    setSessionTag(tagId) {
      axios
        .get(
          `/go/${this.school_slug}/tags/${tagId}/set_tag_for_individual_assessment/${this.session.id}`,
        )
        .then(response => {
          if (response.data.success) {
            this.sessionState.attached_tags = response.data.attached_tags;
          }
        });
    },
    toggleAccordionItem(index, selectedId,itemId) {
      if (this.selectedObjective && this.selectedObjective.id === selectedId && this.selectedObjective.id === itemId) {
        this.selectedObjective = {};
        this.activeAccordionItems.push(index);
      }

      const isOpen = this.activeAccordionItems.includes(index);
    
      if (isOpen) {
        this.activeAccordionItems = this.activeAccordionItems.filter(item => item !== index);
      } else {
        this.activeAccordionItems.push(index);
      }
    },
    getInitialSelectedItems() {
      return this.session.objectives.flatMap(item =>
        item.checklist_items_list.filter(subItem => subItem.checked)
      );
    },
    revertChecklistItemsToInitialState() {
      this.checklistItems.forEach(item => {
        const initialItem = _.find(this.initialChecklistItems, { id: item.id });
        item.checklist_items_list.forEach(subItem => {
          subItem.checked = _.find(initialItem.checklist_items_list, { name: subItem.name }).checked;
        });
      });
    },
    onPressSaveChecklist() {
      this.selectedItems = this.checklistItems.flatMap(item =>
        item.checklist_items_list.filter(subItem => subItem.checked)
      );
  
      this.$refs.checkListPanel.toggleVisible()
      this.activeAccordionItems = []
      this.removeFixedFooter()

      const formData = new FormData()
      formData.append('_method', 'patch')
      formData.append('individual_assessment[checklist_item_ids][]', "")
      this.selectedItems.forEach(item => {
        formData.append('individual_assessment[checklist_item_ids][]', item.id)
      });
      formData.append('commit', 'Save Checklist')
  
      axios.post(`/go/${this.school_slug}/individual_sessions/${this.sessionState.id}/update_checklist`, formData, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => {
        this.initialChecklistItems = _.cloneDeep(this.sessionState.objectives)
        this.searchQuery = ''
      })
      .catch(error => {
        const statusCode = error.response.status
      });
    },
    onPressCancelChecklist() {
      this.revertChecklistItemsToInitialState()
      this.$refs.checkListPanel.toggleVisible()
      this.activeAccordionItems = []
      this.removeFixedFooter()
      this.searchQuery = ''
    },
    removeFixedFooter() {
      const footerElement = document.querySelector('.check-list-footer');
      if (footerElement) {
        setTimeout(() => {
          footerElement.classList.remove('fixed-footer');
        }, 300);
      }
    },
  },
  mounted() {
    this.initializeFileUpload()
    this.mediumDataCollection = []
    this.linkedUrls = [];
    this.iframeLinkUrls = []
    this.nextStep.links = []
    this.progress = "100%"
    document.dispatchEvent(new Event('vue:load'))
    EventBus.$on('modal-opening', () => {
      this.modalOpen = true
    })
    EventBus.$on('modal-closing', () => {
      this.modalOpen = false
    })
    $("#delete-next-step>.x-close-icon-alt").on('click', () => {
      this.$refs.modalBackdrop.toggleVisible();
    });
    $("#add-next-step-form label, #single-next-step-edit-form label").on('click', (event) => {
      event.preventDefault();
      event.stopPropagation();
    });

    $(document).ready(function() {
      if($("#historical-session-notes-panel").length > 0){
        $('head').append('<style>' +
            'html,body {' +
            '     overflow: auto !important;' +
            '     height: 100%;' +
            '}' +
            '</style>');
      }
    });

    this.$rewriteNotesPopup = $("#rewrite-notes-popup");
  },
})
