import Vue from 'vue/dist/vue.esm'
import template from './slide_up_panel_template.slim'

Vue.component('slide-up-panel', {
  mixins: [template],
  props: ['show_close_button'],
  data() {
    return {
      isVisible: false,
      showCloseButton:
        this.show_close_button === undefined ? true : this.show_close_button,
      $rewriteNotesPopup: null,
      $rewriteNotesLoading: null
    }
  },
  methods: {
    toggleVisible() {
      this.isVisible = !this.isVisible

      if (!this.$rewriteNotesLoading.is(":visible") || (this.$rewriteNotesLoading.is(":visible") && !this.isVisible && this.$el.id === "objective-note")) {
        this.$rewriteNotesPopup.fadeOut();
      }
    },
  },
  mounted() {
    this.$rewriteNotesPopup = $("#rewrite-notes-popup");
    this.$rewriteNotesLoading = $("#rewrite-notes-loading");
  }
})
